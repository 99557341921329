<template lang="html">
    <div class="">
        <div class="uk-margin">
            <img :src="preview" style="max-height: 300px">
        </div>

        <div class="uk-margin">
            <form @submit.prevent="onUpload">
                <input type="file" accept="image/*" capture="user" @change="onSnap" id="preview">
                <button type="submit" name="button" v-if="preview">Upload</button>
                <i class="fa fa-spinner fa-spin" aria-hidden="true" v-if="isUploading"></i>
                <span v-if="isUploading">{{ progress }}%</span>
            </form>
        </div>

        <div class="uk-margin">
            <i class="fa fa-spinner fa-spin" aria-hidden="true" v-if="isLoading"></i>
            <wall :images="images"></wall>
        </div>

        <div class="uk-margin">
            <ul>
                <li v-for="(i, id) in images">
                    {{ i }}
                    <img :src="i.src" style="max-height: 50px" />
                    <button type="button" name="button" @click.prevent="onRemove(id)">Remove</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed : mapState([
        "images",
        "preview",
        "isUploading",
        "progress",
        "isLoading"
    ]),
    methods : {
        onSnap (e) {
            this.$store.dispatch("snapImage", e);
        },
        onUpload (e) {
            this.$store.dispatch("uploadImage", e);
        },
        onRemove (id) {
            this.$store.dispatch("removeImage", id);
        }
    }
}
</script>

<style lang="css" scoped>
</style>
