<template lang="html">
    <v-stage :config="configKonva" @mousedown="handleStageMouseDown" @tap="handleStageMouseDown">
        <v-layer>
            <v-rect
                v-for="(i, key) in images"
                :key="key"
                :config="{
                    name: i.name,
                    width: i.width,
                    height: i.height,
                    x : i.x,
                    y : i.y,
                    scaleX : i.scaleX,
                    scaleY : i.scaleY,
                    rotation : i.rotation,
                    fillPatternImage: i.fillPatternImage,
                    stroke: 'white',
                    strokeWidth: 10,
                    draggable : true,
                    dragBoundFunc: handleDragBoundFunc(i),
                    lineJoin: 'round',
                    opacity: (i.isDragged ? 0.3 : 1),
                    shadowColor: 'black',
                    shadowBlur: 20,
                    shadowOffset: { x: 5, y: 5 },
                    shadowOpacity: 0.3
                }"
                @dragStart="(e) => handleDragStart(e, key, i)"
                @dragend="(e) => handleDragEnd(e, key, i)"
                @transform="(e) => handleTransform(e, key, i)"
                @transformend="(e) => handleTransformend(e, key, i)">
            </v-rect>
            <v-transformer ref="transformer" :config="{
                keepRatio: true,
                centeredScaling: true,
                rotationSnaps: [0, 90, 180, 270],
                resizeEnabled: false,
                anchorSize: 20,
                // enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right']
            }"></v-transformer>
        </v-layer>
    </v-stage>
</template>

<script>
import Konva from "konva";

export default {
    props : [
        "images"
    ],
    data () {
        return {
            stage : null,
            layer : null,
            configKonva : {
                width: 400,
                height: 400,
                //draggable: true
            },
            selectedShapeName: ''
        };
    },
    watch : {
        images : function (v) {
            if (this.selectedShapeName == '') {
                return;
            }

            for (var i in this.images) {
                if (this.images[i].name == this.selectedShapeName) {
                    return;
                }
            }

            this.selectedShapeName = '';
            this.updateTransformer();
        }
    },
    methods : {
        handleDragStart (e, id, i) {
            this.$store.dispatch("startMoveImage", {
                id : id
            });
        },
        handleDragEnd (e, id, i) {
            this.$store.dispatch("moveImage", {
                id : id,
                x : e.currentTarget.attrs.x,
                y : e.currentTarget.attrs.y
            });
        },
        handleStageMouseDown (e) {
            // clicked on stage - cler selection
            if (e.target === e.target.getStage()) {
                this.selectedShapeName = '';
                this.updateTransformer();
                return;
            }

            // clicked on transformer - do nothing
            const clickedOnTransformer = e.target.getParent().className === 'Transformer';
            if (clickedOnTransformer) {
                return;
            }

            // find clicked rect by its name
            const name = e.target.name();

            var img = false;
            for (var i in this.images) {
                if (this.images[i].name == name) {
                    img = this.images[i];
                    break;
                }
            }

            if (img) {
                this.selectedShapeName = name;
            } else {
                this.selectedShapeName = '';
            }
            this.updateTransformer();
        },
        updateTransformer () {
            // here we need to manually attach or detach Transformer node
            const transformerNode = this.$refs.transformer.getStage();
            const stage = transformerNode.getStage();
            const { selectedShapeName } = this;

            const selectedNode = stage.findOne('.' + selectedShapeName);

            // do nothing if selected node is already attached
            if (selectedNode === transformerNode.node()) {
                return;
            }

            if (selectedNode) {
                // attach to another node
                transformerNode.attachTo(selectedNode);
            } else {
                // remove transformer
                transformerNode.detach();
            }
            transformerNode.getLayer().batchDraw();
        },
        handleTransform (e, id, i) {
            if (i.isDragged) {
                return;
            }

            this.$store.dispatch("startMoveImage", {
                id : id
            });
        },
        handleTransformend (e, id, i) {
            this.$store.dispatch("transformedImage", {
                id : id,
                attrs : e.currentTarget.attrs
            });
        },
        handleDragBoundFunc (i) {
            /*
            return (pos) => {
                var newX = pos.x;

                if ((pos.x - i.width) < 0) {
                    newX = pos.x - i.width;
                } else if ((pos.x + i.width) > this.configKonva.width) {
                    newX = (this.configKonva.width - i.width);
                }

                var newY = pos.y;

                if ((pos.y - i.height) < 0) {
                    newY = pos.y - i.height;
                } else if ((pos.y + i.height) > this.configKonva.height) {
                    newY = (this.configKonva.height - i.height);
                }

                return {
                    x: newX,
                    y: newY
                };
            };
            */
        }
    }
}
</script>

<style lang="css" scoped>
</style>
