import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import Async from "async";
import Firebase from "firebase/app";

Vue.use(Vuex);

Axios.defaults.baseURL = "https://s.coderi.sk/api";
// Axios.defaults.baseURL = "http://localhost:8080/coderi.sk/sub/s/api";

const store = new Vuex.Store({
    state : {
        images : {},
        preview : "",
        isUploading : false,
        progress : 0,
        isLoading : false
    },
    mutations : {
        setImages (state, images) {
            state.images = images;
        },
        setPreview (state, preview) {
            state.preview = preview;
        },
        setIsUploading (state, isUploading) {
            state.isUploading = isUploading;
        },
        setProgress (state, progress) {
            state.progress = progress;
        },
        setIsLoading (state, isLoading) {
            state.isLoading = isLoading;
        }
    },
    actions : {
        loadImages (context, images) {
            context.commit("setIsLoading", true);
            Async.mapValues(images, function (item, key, callback) {
                var src = Axios.defaults.baseURL + "/images/" + item.name + "-256x256";
                const img = new window.Image();
                img.src = src;
                img.onload = function () {
                    var config = {
                        name : key,
                        x : item.x,
                        y : item.y,
                        width: img.width,
                        height: img.height,
                        rotation : item.rotation,
                        scaleX : item.scaleX,
                        scaleY : item.scaleY,
                        src : src,
                        fillPatternImage: img,
                        stroke: 'white',
                        strokeWidth: 10,
                        draggable : true,
                        lineJoin: 'round',
                        opacity: (item.isDragged ? 0.3 : 1),
                        shadowColor: 'black',
                        shadowBlur: 20,
                        shadowOffset: { x: 5, y: 5 },
                        shadowOpacity: 0.3
                    };
                    callback(null, config);
                };
            }, function (err, results) {
                context.commit("setImages", results);
                context.commit("setIsLoading", false);
            });
        },
        subscribeImages (context) {
            Firebase.database().ref("images").on("value", function (snapshot) {
                var images = snapshot.val();
                context.dispatch("loadImages", images);
            });
        },
        snapImage (context, e) {
            var input = e.target;

            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = (ev) => {
                    context.commit("setPreview", ev.target.result);
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        addImage (context, name) {
            Firebase.database().ref("images/" + name).set({
                name : name,
                x : 0,
                y : 0,
                isDragged : false
            });

            context.commit("setPreview", "");
            context.commit("setIsUploading", false);
        },
        removeImage (context, id) {
            if (!confirm("Really?")) {
                return;
            }

            var image = context.state.images[id];

            Axios.delete("/images/" + image.name)
                .then(function (response) {
                    Firebase.database().ref("images/" + id).remove();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        startMoveImage (context, payload) {
            Firebase.database().ref("images/" + payload.id).update({
                isDragged : true
            });
        },
        moveImage (context, payload) {
            Firebase.database().ref("images/" + payload.id).update({
                x : payload.x,
                y : payload.y,
                isDragged : false
            });
        },
        transformedImage (context, payload) {
            Firebase.database().ref("images/" + payload.id).update({
                rotation : payload.attrs.rotation,
                scaleX : payload.attrs.scaleX,
                scaleY : payload.attrs.scaleY,
                isDragged : false
            });
        },
        uploadImage (context, e) {
            var input = document.getElementById("preview");

            if (input.files && input.files[0]) {
                context.commit("setProgress", 0);
                context.commit("setIsUploading", true);

                var image = input.files[0];
                var formData = new FormData();
                    formData.append("image", image);

                Axios.post("/images", formData, {
                    onUploadProgress (progressEvent) {
                        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                        context.commit("setProgress", percentCompleted);
                    }
                })
                .then(function (response) {
                    input.value = "";
                    context.dispatch("addImage", response.data.name);
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }
    }
});

export default store;
