import Vue from "vue";
import Firebase from "firebase/app";
import "firebase/database";
import VueKonva from "vue-konva";

Vue.use(VueKonva);

/*
 *  Firebase
 */
var firebaseConfig = {
    apiKey: "AIzaSyCJl0r_I3HvzsEbAOWR_RMUn4EThbr-LdU",
    authDomain: "mo20-6cc36.firebaseapp.com",
    databaseURL: "https://mo20-6cc36.firebaseio.com",
    projectId: "mo20-6cc36",
    storageBucket: "",
    messagingSenderId: "710535379714",
    appId: "1:710535379714:web:08da9a05c0c6d9ec"
};
Firebase.initializeApp(firebaseConfig);

import store from "./store";
import router from "./router";

import App from "./pages/App.vue";

store.dispatch("subscribeImages");

/*
 *  Application
 */
const app = new Vue({
    store,
    router,
    render: h => h(App)
}).$mount("#app");
